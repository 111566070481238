import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const OurServicesPage = () => (
  <Layout>
    <SEO title="Our Services" />
    <h1>Our services</h1>
    <p>Latin text, lalala.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default OurServicesPage
